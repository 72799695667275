import axios from 'axios';
import crypto from 'crypto-browserify';
import qs from 'qs';

interface RoutingParams {
  start: [number, number];
  end: [number, number];
  batteryCapacity: number;
  initialCharge: number;
  minChargeAtArrival: number;
  chargingCurve: string;
  evParams?: {
    connectorTypes?: string[];
    initialCharge?: number;
    maxCharge?: number;
    chargingCurve?: string;
    maxChargeAfterChargingStation?: number;
    minChargeAtChargingStation?: number;
    minChargeAtDestination?: number;
    chargingSetupDuration?: number;
    auxiliaryConsumption?: number;
    ascent?: number;
    descent?: number;
    makeReachable?: boolean;
    freeFlowSpeedTable?: string;
    trafficSpeedTable?: string;
    departureTime?: string;
  };
  [key: string]: any;
}

export interface RoutingLocation {
  time: string;
  place: {
    address? : {
      label: string;
    }
    id: string;
    type: string;
    name?: string;
    location: {
      lat: number;
      lng: number;
      elv: number;
    },
    originalLocation: {
      lat: number;
      lng: number;
    },
    attributes?: {
      connectorType?: string;
      current?: number;
      power?: number;
      supplyType?: string;
      voltage?: number;
    },
    brand?: {
      hrn?: string;
      name?: string;
    };
  },
  charge: number
}

export interface RoutingSpan {
  consumption: number;
  offset: number;
  topologySegmentId: string;
}

export interface PostAction {
  action: string;
  arrivalCharge: number;
  consumablePower: number;
  duration: number;
  targetCharge: number;
}

export interface Section {
  id: string;
  type: string;
  departure: RoutingLocation;
  arrival: RoutingLocation;
  summary: {
    duration: number;
    length: number;
    consumption: number;
    baseDuration: number;
  };
  polyline: string;
  postActions: PostAction[];
  spans: RoutingSpan[];
  transport: {
    mode: string;
  };
  consumptionType: string;
}

interface RouteSection {
  id: string;
  sections: Section[];
}

interface RouteResponse {
  routes: RouteSection[];
}

interface ReverseGeocodingResponse {
  items: Array<{
    title: string;
    address: {
      label: string;
    };
  }>;
}

// Add this new function for reverse geocoding
export const reverseGeocode = async (lat: number, lng: number, accessToken: string): Promise<string> => {
  try {
    const response = await axios.get<ReverseGeocodingResponse>('https://revgeocode.search.hereapi.com/v1/revgeocode', {
      params: {
        at: `${lat},${lng}`,
        lang: 'en-US',
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.data.items && response.data.items.length > 0) {
      return response.data.items[0].address.label;
    }
    return 'Address not found';
  } catch (error) {
    console.error('Error in reverse geocoding:', error);
    return 'Error fetching address';
  }
};

const generateSignature = (baseString: string, signingKey: string): string => {
  return crypto
    .createHmac('sha256', signingKey)
    .update(baseString)
    .digest('base64');
};

const getSigningKey = (consumerSecret: string, tokenSecret: string = ''): string => {
  return `${encodeURIComponent(consumerSecret)}&${encodeURIComponent(tokenSecret)}`;
};

const getBaseString = (method: string, url: string, params: any): string => {
  const sortedParams = Object.keys(params).sort().map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  return `${method.toUpperCase()}&${encodeURIComponent(url)}&${encodeURIComponent(sortedParams)}`;
};

export const getAccessToken = async () => {
  const oauth_consumer_key = process.env.REACT_APP_CONSUMER_KEY!;
  const oauth_consumer_secret = process.env.REACT_APP_CONSUMER_SECRET!;
  const oauth_nonce = Math.random().toString(36).substring(2);
  const oauth_timestamp = Math.floor(Date.now() / 1000).toString();
  const oauth_signature_method = 'HMAC-SHA256';
  const oauth_version = '1.0';
  const grant_type = 'client_credentials';
  const url = 'https://account.api.here.com/oauth2/token';

  const params = {
    grant_type,
    oauth_consumer_key,
    oauth_nonce,
    oauth_signature_method,
    oauth_timestamp,
    oauth_version,
  };

  const baseString = getBaseString('POST', url, params);
  const signingKey = getSigningKey(oauth_consumer_secret);
  const oauth_signature = generateSignature(baseString, signingKey);

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `OAuth oauth_consumer_key="${oauth_consumer_key}",oauth_nonce="${oauth_nonce}",oauth_signature="${encodeURIComponent(oauth_signature)}",oauth_signature_method="${oauth_signature_method}",oauth_timestamp="${oauth_timestamp}",oauth_version="${oauth_version}"`
  };

  const data = qs.stringify({ 'grant_type': 'client_credentials' });

  const response = await axios.post(url, data, { headers });
  return response.data.access_token;
};

const generateChargingCurve = (batteryCapacity: number, maxChargeAfterChargingStation: number): string => {
  const steps = 20; // Number of points in the curve
  const maxCharge = Math.max(batteryCapacity, maxChargeAfterChargingStation);
  let curve = '';

  for (let i = 0; i <= steps; i++) {
    const chargePercentage = (i / steps) * 100;
    const chargePower = 150 - (chargePercentage / 100) * 100; // Simple linear decrease from 150kW to 50kW
    curve += `${chargePercentage.toFixed(0)},${chargePower.toFixed(0)}`;
    if (i < steps) curve += ',';
  }

  return curve;
};

export const getRoute = async (params: RoutingParams, accessToken: string): Promise<RouteResponse> => {
  const chargingCurve = generateChargingCurve(
    params.batteryCapacity,
    params.evParams?.maxChargeAfterChargingStation || params.batteryCapacity
  );

  const response = await axios.get<RouteResponse>('https://router.hereapi.com/v8/routes', {
    params: {
      origin: `${params.start[0]},${params.start[1]}`,
      destination: `${params.end[0]},${params.end[1]}`,
      transportMode: 'car',
      return: 'summary,polyline',
      spans: 'consumption,segmentId',
      'ev[batteryCapacity]': params.batteryCapacity,
      'ev[initialCharge]': params.initialCharge,
      'ev[minChargeAtArrival]': params.minChargeAtArrival,
      'ev[chargingCurve]': chargingCurve,
      ...params.evParams && {
        'ev[connectorTypes]': params.evParams.connectorTypes?.join(',') || '',
        'ev[maxCharge]': params.evParams.maxCharge,
        'ev[maxChargeAfterChargingStation]': params.evParams.maxChargeAfterChargingStation,
        'ev[minChargeAtChargingStation]': params.evParams.minChargeAtChargingStation,
        'ev[minChargeAtDestination]': params.evParams.minChargeAtDestination,
        'ev[chargingSetupDuration]': params.evParams.chargingSetupDuration,
        'ev[auxiliaryConsumption]': params.evParams.auxiliaryConsumption,
        'ev[ascent]': params.evParams.ascent,
        'ev[descent]': params.evParams.descent,
        'ev[makeReachable]': params.evParams.makeReachable,
        'ev[freeFlowSpeedTable]': params.evParams.freeFlowSpeedTable,
        'ev[trafficSpeedTable]': params.evParams.trafficSpeedTable,
        'departureTime': params.evParams.departureTime || new Date().toISOString(),
      }
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // Perform reverse geocoding for start and end points
  const startAddress = await reverseGeocode(params.start[0], params.start[1], accessToken);
  const endAddress = await reverseGeocode(params.end[0], params.end[1], accessToken);

  // Add the addresses to the response
  if (response.data.routes && response.data.routes.length > 0) {
    const route = response.data.routes[0];
    if (route.sections && route.sections.length > 0) {
      route.sections[0].departure.place.address = { label: startAddress };
      route.sections[route.sections.length - 1].arrival.place.address = { label: endAddress };
    }
  }

  return response.data;
};