import React, { useEffect } from 'react';
import { Popup } from 'react-leaflet';
import { format, parseISO } from 'date-fns';

interface CustomRoutingPopupProps {
    type: 'start' | 'end' | 'charging' | 'waypoint';
    title: string;
    address: string;
    time: string;
    charge: number;
    duration?: number;
    distance?: number;
    consumption?: number;
    chargingTime?: number;
    chargingPower?: number;
    connectorType?: string;
    current?: number;
    voltage?: number;
    supplyType?: string;
}

const formatDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  return `${hours}h ${mins}m`;
};

export const CustomRoutingPopup: React.FC<CustomRoutingPopupProps> = ({
    type,
    title,
    address,
    time,
    charge,
    duration,
    distance,
    consumption,
    chargingTime,
    chargingPower,
    connectorType,
    current,
    voltage,
    supplyType,
  }) => {
    const formattedTime = format(parseISO(time), 'MMM d, HH:mm');
  
    const renderChargingInfo = () => {
      if (type !== 'charging') return null;
      
      return (
        <>
          {chargingTime !== undefined && (
            <p className="flex items-center">
              <span className="text-2xl mr-2">🔋</span>
              <span>Charging Time: {formatDuration(chargingTime)}</span>
            </p>
          )}
          {chargingPower !== undefined && (
            <p className="flex items-center">
              <span className="text-2xl mr-2">⚡</span>
              <span>Power: {chargingPower} kW</span>
            </p>
          )}
          {connectorType && (
            <p className="flex items-center">
              <span className="text-2xl mr-2">🔌</span>
              <span>Connector: {connectorType}</span>
            </p>
          )}
          {current !== undefined && (
            <p className="flex items-center">
              <span className="text-2xl mr-2">🔌</span>
              <span>Current: {current} A</span>
            </p>
          )}
          {voltage !== undefined && (
            <p className="flex items-center">
              <span className="text-2xl mr-2">🔌</span>
              <span>Voltage: {voltage} V</span>
            </p>
          )}
          {supplyType && (
            <p className="flex items-center">
              <span className="text-2xl mr-2">🔌</span>
              <span>Supply Type: {supplyType}</span>
            </p>
          )}
        </>
      );
    };

  return (
    <Popup className="custom-popup">
      <div className="popup-content w-72 max-w-sm">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h3 className="text-lg font-semibold text-cyber-blue">{title}</h3>
            <p className="text-sm text-gray-400">{address}</p>
          </div>
          <div className="text-4xl">
            {type === 'start' && '🚗'}
            {type === 'end' && '🏁'}
            {type === 'charging' && '🔌'}
            {type === 'waypoint' && '📍'}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="bg-gray-800 bg-opacity-50 rounded-lg p-3">
            <p className="text-xs text-gray-400 mb-1">Time</p>
            <p className="text-lg font-semibold">{formattedTime}</p>
          </div>
          <div className="bg-gray-800 bg-opacity-50 rounded-lg p-3 flex items-center justify-center">
            <div className="relative w-16 h-16">
              <svg className="w-16 h-16 transform -rotate-90" viewBox="0 0 36 36">
                <circle cx="18" cy="18" r="15.9155" fill="none" stroke="#4B5563" strokeWidth="2" />
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="none"
                  stroke="#10B981"
                  strokeWidth="2"
                  strokeDasharray={`${charge * 100 / 100}, 100`}
                  strokeLinecap="round"
                />
              </svg>
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-md font-bold">{charge.toFixed(1)}%</span>
              </div>
            </div>
          </div>
        </div>
        {type !== 'start' && (
          <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
            {duration !== undefined && (
              <p className="flex items-center">
                <span className="text-2xl mr-2">⏱️</span>
                <span>Duration: {formatDuration(duration)}</span>
              </p>
            )}
            {distance !== undefined && (
              <p className="flex items-center">
                <span className="text-2xl mr-2">🛣️</span>
                <span>Distance: {distance.toFixed(1)} km</span>
              </p>
            )}
            {consumption !== undefined && (
              <p className="flex items-center">
                <span className="text-2xl mr-2">⚡</span>
                <span>Energy Used: {consumption.toFixed(2)} kWh</span>
              </p>
            )}
            {renderChargingInfo()}
          </div>
        )}
      </div>
    </Popup>
  );
};