import React, { useState, useEffect } from 'react';
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import { getChargePoints, ChargePoint } from '../services/evChargePoints';
import { CustomChargingPopup } from './charging-popup';

const chargingIcon = new L.Icon({
  iconUrl: 'https://i.imgur.com/gjX1Qc7.png',
  iconSize: [35, 35],
  iconAnchor: [17, 35],
  popupAnchor: [0, -35],
  className: 'charging-icon',
});

interface ChargePointsLayerProps {
  connectorType?: string;
}

export const ChargePointsLayer: React.FC<ChargePointsLayerProps> = ({ connectorType }) => {
  const [chargePoints, setChargePoints] = useState<ChargePoint[]>([]);
  const map = useMap();

  useEffect(() => {
    const fetchChargePoints = async () => {
      const bounds = map.getBounds();
      const center = bounds.getCenter();
      try {
        const data = await getChargePoints({
          lat: center.lat,
          lng: center.lng,
          connectorType: connectorType
        });
        setChargePoints(data.items);
      } catch (error) {
        console.error('Error fetching charge points:', error);
      }
    };

    fetchChargePoints();

    map.on('moveend', fetchChargePoints);

    return () => {
      map.off('moveend', fetchChargePoints);
    };
  }, [map, connectorType]);

  return (
    <>
      {chargePoints.map((point) => (
        <Marker key={point.id} position={[point.position.lat, point.position.lng]} icon={chargingIcon}>
          <CustomChargingPopup
            title={point.title}
            address={point.address.label}
            distance={point.distance}
            categories={point.categories}
            chains={point.chains}
            contacts={point.contacts}
            openingHours={point.openingHours}
          />
        </Marker>
      ))}
    </>
  );
};