import React from 'react';
import { Popup } from 'react-leaflet';

interface CustomChargingPopupProps {
  title: string;
  address: string;
  distance: number;
  categories: { name: string }[];
  chains?: { name: string }[];
  contacts?: {
    phone?: { value: string }[];
    www?: { value: string }[];
  }[];
  openingHours?: {
    text: string[];
    isOpen: boolean;
  }[];
}

export const CustomChargingPopup: React.FC<CustomChargingPopupProps> = ({
  title,
  address,
  distance,
  categories,
  chains,
  contacts,
  openingHours,
}) => {
  return (
    <Popup className="custom-popup">
      <div className="bg-gray-900 text-white rounded-lg shadow-lg p-4 w-72">
        <h3 className="text-xl font-bold mb-2">{title}</h3>
        <p className="text-sm mb-2">{address}</p>
        <p className="text-sm mb-2">Distance: {distance} meters</p>
        <div className="mb-2">
          <p className="text-sm font-semibold">Categories:</p>
          <ul className="list-disc list-inside text-xs">
            {categories.map((cat, index) => (
              <li key={index}>{cat.name}</li>
            ))}
          </ul>
        </div>
        {chains && chains.length > 0 && (
          <p className="text-sm mb-2">Chain: {chains[0].name}</p>
        )}
        {contacts && contacts.length > 0 && (
          <div className="mb-2">
            {contacts[0].phone && (
              <p className="text-sm">
                Phone: <a href={`tel:${contacts[0].phone[0].value}`} className="text-blue-400">{contacts[0].phone[0].value}</a>
              </p>
            )}
            {contacts[0].www && (
              <p className="text-sm">
                Website: <a href={contacts[0].www[0].value} target="_blank" rel="noopener noreferrer" className="text-blue-400">Visit</a>
              </p>
            )}
          </div>
        )}
        {openingHours && openingHours.length > 0 && (
          <div className="text-sm">
            <p className="font-semibold">Opening Hours:</p>
            <p>{openingHours[0].text[0]}</p>
            <p className={openingHours[0].isOpen ? "text-green-400" : "text-red-400"}>
              {openingHours[0].isOpen ? "Open Now" : "Closed"}
            </p>
          </div>
        )}
      </div>
    </Popup>
  );
};