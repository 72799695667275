import axios from 'axios';

export interface ChargePoint {
  id: string;
  title: string;
  position: { lat: number; lng: number };
  address: {
    label: string;
    countryCode: string;
    countryName: string;
    stateCode: string;
    state: string;
    city: string;
    district: string;
    street: string;
    postalCode: string;
    houseNumber: string;
  };
  distance: number;
  categories: { id: string; name: string; primary: boolean }[];
  chains?: { name: string }[];
  contacts?: {
    phone?: { value: string }[];
    www?: { value: string }[];
  }[];
  openingHours?: {
    text: string[];
    isOpen: boolean;
  }[];
}

interface ChargePointsResponse {
  items: ChargePoint[];
}

interface ChargePointsParams {
  lat: number;
  lng: number;
  radius?: number;
  connectorType?: string;
}

export const getChargePoints = async (params: ChargePointsParams): Promise<ChargePointsResponse> => {
  const response = await axios.get<ChargePointsResponse>('https://browse.search.hereapi.com/v1/browse', {
    params: {
      at: `${params.lat},${params.lng}`,
      categories: '700-7600-0322',
      ...(params.radius && { radius: params.radius }),
    //   ...(params.connectorType && { connectorType: params.connectorType }),
      apiKey: process.env.REACT_APP_HERE_API_KEY,
    },
  });
  return response.data;
};
