import { createElementObject, createPathComponent, extendContext, LeafletContextInterface, LeafletElement } from '@react-leaflet/core';
import chroma from 'chroma-js';
import L, { LatLngTuple } from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { FC, useCallback } from 'react';
import { PolylineProps } from 'react-leaflet';

interface Props {
  color: string;
  data: LatLngTuple[];
  paused: boolean;
}

interface AntPathProps extends PolylineProps {
  positions: LatLngTuple[];
}

export const AntPath: FC<Props> = ({ color, data, paused }) => {
  const createPolyline = useCallback(({ positions, ...options }: AntPathProps, ctx: LeafletContextInterface): LeafletElement<L.Polyline> => {
    const polyline = antPath(positions, {
      color: chroma(color).alpha(0.3),
      'dashArray': [
        10,
        50,
      ],
      'delay': 1000,
      'hardwareAccelerated': true,
      paused,
      opacity: 1,
      pulseColor: chroma(color).alpha(0.75),
      'reverse': false,
      'weight': 5,
      interactive: true,
      ...options,
    });

    return createElementObject(polyline, extendContext(ctx, { overlayContainer: polyline }));
  }, [color, paused]);

  const AntPathPolyline = createPathComponent<L.Polyline, AntPathProps>(createPolyline);

  return (
    <AntPathPolyline positions={data} />
  );
};
