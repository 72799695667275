import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { PostAction, Section } from '../services/routing';
import { AntPath } from './antpath';
import { CustomRoutingPopup } from './routing-popup';

const startIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const endIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const chargingIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-yellow.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const chargeStateIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [18, 30],  // Smaller size for waypoint markers
  iconAnchor: [6, 20],
  popupAnchor: [1, -17],
  shadowSize: [20, 20]
});

export interface SectionWaypoint extends Section {
  waypoints: [number, number][]
}

interface RouteLayerProps {
  sections: SectionWaypoint[];
}

const getColorBasedOnConsumption = (consumption: number) => {
  if (consumption < 0.01) return 'green';
  if (consumption < 0.02) return 'yellow';
  if (consumption < 0.05) return 'orange';
  return 'red';
};

const getChargingDuration = (postActions: PostAction[] | undefined): number | undefined => {
  const chargingAction = postActions?.find(action => action.action === 'charging');
  const duration = chargingAction ? Math.round(chargingAction.duration / 60) : undefined;
  return duration;
};


export const RouteLayer: React.FC<RouteLayerProps> = ({ sections }) => {
  return (
    <>
      {sections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          {section.spans.map((span, index: number) => {
            const startOffset = span.offset;
            const endOffset = section.spans[index + 1] ? section.spans[index + 1].offset : section.waypoints.length;
            const spanCoordinates = section.waypoints.slice(startOffset, endOffset);

            return (
              <AntPath
                key={index}
                data={spanCoordinates}
                color={getColorBasedOnConsumption(span.consumption)}
                paused={false}
              />
            );
          })}
          {section.departure && (
            <Marker
              position={[section.departure.place.location.lat, section.departure.place.location.lng]}
              icon={sectionIndex === 0 ? startIcon : chargingIcon}
            >
              <CustomRoutingPopup
                type={sectionIndex === 0 ? "start" : "charging"}
                title={sectionIndex === 0 ? "Start" : "Charging Stop"}
                address={section.departure.place.name || section.departure.place.address?.label || ""}
                time={section.departure.time}
                charge={section.departure.charge}
                {...(section.departure.place.attributes && {
                  connectorType: section.departure.place.attributes.connectorType,
                  current: section.departure.place.attributes.current,
                  voltage: section.departure.place.attributes.voltage,
                  chargingPower: section.departure.place.attributes.power,
                  supplyType: section.departure.place.attributes.supplyType,
                })}
              />
            </Marker>
          )}
          {section.arrival && section.arrival.place.type !== 'waypoint' && (
              <Marker
                position={[section.arrival.place.location.lat, section.arrival.place.location.lng]}
                icon={sectionIndex === sections.length - 1 ? endIcon : 
                      section.arrival.place.type === 'chargingStation' ? chargingIcon : 
                      endIcon}
              >
                <CustomRoutingPopup
                  type={sectionIndex === sections.length - 1 ? "end" : 
                        section.arrival.place.type === 'chargingStation' ? "charging" : "waypoint"}
                  title={sectionIndex === sections.length - 1 ? "End" : 
                         section.arrival.place.type === 'chargingStation' ? "Charging Stop" : "Waypoint"}
                  address={section.arrival.place.address?.label || "Address not available"}
                  time={section.arrival.time}
                  charge={section.arrival.charge}
                  duration={Math.round(section.summary.duration / 60)}
                  distance={section.summary.length / 1000}
                  consumption={section.summary.consumption}
                  chargingTime={getChargingDuration(section.postActions)}
                  chargingPower={section.arrival.place.attributes?.power}
                  connectorType={section.arrival.place.attributes?.connectorType}
                  current={section.arrival.place.attributes?.current}
                  voltage={section.arrival.place.attributes?.voltage}
                  supplyType={section.arrival.place.attributes?.supplyType}
                />
              </Marker>
            )}
          {/* Add charge state markers along the route */}
          {/* {section.spans.filter((_, index: number) => index % 10 === 0).map((span: any, index: number) => {
            const position = section.waypoints[span.offset];
            const charge = section.departure.charge - (section.summary.consumption * (span.offset / section.waypoints.length));
            return (
              <Marker
                key={`charge-${sectionIndex}-${index}`}
                position={position}
                icon={chargeStateIcon}
              >
                <CustomRoutingPopup
                  type="waypoint"
                  title="Charge State"
                  address=""
                  time={section.departure.time}
                  charge={charge}
                  consumption={span.consumption}
                />
              </Marker>
            );
          })} */}
        </React.Fragment>
      ))}
    </>
  );
};