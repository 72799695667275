import React, { useState, useCallback } from 'react';
import TimePicker from 'react-time-picker';
import { Value } from 'react-time-picker/dist/cjs/shared/types';
import { getRoute, getAccessToken, Section } from '../services/routing';
import { searchLocation, GeocodeItem } from '../services/geocoding';
import { decode } from '@here/flexpolyline';
import debounce from 'lodash/debounce';
import L from 'leaflet';
import { SectionWaypoint } from './route-layer';

interface SidebarProps {
  setSections: React.Dispatch<React.SetStateAction<SectionWaypoint[]>>;
  setMapBounds: (bounds: L.LatLngBoundsExpression) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ setSections, setMapBounds }) => {
  const [start, setStart] = useState<[number, number]>([-37.851680, 145.079050]);
  const [end, setEnd] = useState<[number, number]>([-37.835500, 144.960010]);
  const [startAddress, setStartAddress] = useState<string>('');
  const [endAddress, setEndAddress] = useState<string>('');
  const [startSearchResults, setStartSearchResults] = useState<GeocodeItem[]>([]);
  const [endSearchResults, setEndSearchResults] = useState<GeocodeItem[]>([]);
  const [batteryCapacity, setBatteryCapacity] = useState<number>(70);
  const [initialCharge, setInitialCharge] = useState<number>(50);
  const [minChargeAtArrival, setMinChargeAtArrival] = useState<number>(20);
  const [chargingCurve, setChargingCurve] = useState<string>('linear');
  const [connectorType, setConnectorType] = useState<string | undefined>('iec62196Type2Combo');
  const [departureTime, setDepartureTime] = useState<Value>('');
  const [maxCharge, setMaxCharge] = useState<number>(80);
  const [maxChargeAfterChargingStation, setMaxChargeAfterChargingStation] = useState<number>(72);
  const [minChargeAtChargingStation, setMinChargeAtChargingStation] = useState<number>(8);
  const [minChargeAtDestination, setMinChargeAtDestination] = useState<number>(8);
  const [chargingSetupDuration, setChargingSetupDuration] = useState<number>(300);
  const [auxiliaryConsumption, setAuxiliaryConsumption] = useState<number>(1.8);
  const [ascent, setAscent] = useState<number>(9);
  const [descent, setDescent] = useState<number>(4.3);
  const [makeReachable, setMakeReachable] = useState<boolean>(true);
  const [freeFlowSpeedTable, setFreeFlowSpeedTable] = useState<string>('0,0.239,27,0.239,45,0.259,60,0.196,75,0.207,90,0.238,100,0.26,110,0.296,120,0.337,130,0.351,250,0.351');
  const [trafficSpeedTable, setTrafficSpeedTable] = useState<string>('0,0.349,27,0.319,45,0.329,60,0.266,75,0.287,90,0.318,100,0.33,110,0.335,120,0.35,130,0.36,250,0.36');

  const getToken = async () => {
    let token = localStorage.getItem('accessToken');
    if (!token) {
      const tokenData = await getAccessToken();
      localStorage.setItem('accessToken', tokenData as string);
      return tokenData;
    }
    return token;
  };

  const decodePolyline = (encoded: string): [number, number][] => {
    return decode(encoded).polyline as [number, number][];
  };

  const handleRoute = async () => {
    if (start && end) {
      const token = await getToken();
      const params: any = {
        start,
        end,
        initialCharge,
        batteryCapacity,
        minChargeAtArrival,
        evParams: {
          connectorTypes: connectorType ? [connectorType] : undefined,
          departureTime: departureTime || undefined,
          maxCharge: maxCharge || undefined,
          maxChargeAfterChargingStation: maxChargeAfterChargingStation || undefined,
          minChargeAtChargingStation: minChargeAtChargingStation || undefined,
          minChargeAtDestination: minChargeAtDestination || undefined,
          chargingSetupDuration: chargingSetupDuration || undefined,
          auxiliaryConsumption: auxiliaryConsumption || undefined,
          ascent: ascent || undefined,
          descent: descent || undefined,
          makeReachable,
          freeFlowSpeedTable: freeFlowSpeedTable || undefined,
          trafficSpeedTable: trafficSpeedTable || undefined,
          chargingCurve: '0,239,32,199,56,167,60,130,64,111,68,83,72,55,76,33,78,17,80,1'
        },
      };

      const routeParams = Object.fromEntries(Object.entries(params.evParams).filter(([_, v]) => v != null));
      params.evParams = routeParams;

      try {
        const data = await getRoute(params, token as string);
        if (data.routes && data.routes[0].sections) {
          const sectionsData: SectionWaypoint[] = data.routes[0].sections.map((section: Section) => ({
            waypoints: decodePolyline(section.polyline),
            ...section,
          }));
  
          setSections(sectionsData);
  
          const allCoordinates = sectionsData.flatMap((section: SectionWaypoint) => section.waypoints);
          const bounds = L.latLngBounds(allCoordinates);
          setMapBounds(bounds);
        }
      } catch (error) {
        console.error('Error fetching route:', error);
      }
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (query: string, setResults: React.Dispatch<React.SetStateAction<GeocodeItem[]>>) => {
      if (query.length > 3) {
        try {
          const data = await searchLocation({ query });
          setResults(data.items);
        } catch (error) {
          console.error('Error searching for location:', error);
        }
      } else {
        setResults([]);
      }
    }, 300),
    []
  );

  const handleAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setAddress: React.Dispatch<React.SetStateAction<string>>,
    setResults: React.Dispatch<React.SetStateAction<GeocodeItem[]>>
  ) => {
    const query = e.target.value;
    setAddress(query);
    debouncedSearch(query, setResults);
  };

  const handleSelectResult = (
    result: GeocodeItem,
    setCoordinates: React.Dispatch<React.SetStateAction<[number, number]>>,
    setAddress: React.Dispatch<React.SetStateAction<string>>,
    setResults: React.Dispatch<React.SetStateAction<GeocodeItem[]>>
  ) => {
    const { lat, lng } = result.position;
    setCoordinates([lat, lng]);
    setAddress(result.address.label);
    setResults([]);
  };

  return (
    <div className={`sidebar`}>
        <div className="scrollable">
          <h3 className="text-2xl font-bold mb-6">Routing Parameters</h3>
            <div>
              <label className="block mb-2">
                Start:
                <input
                  type="text"
                  value={startAddress}
                  onChange={(e) => handleAddressChange(e, setStartAddress, setStartSearchResults)}
                  placeholder="Enter start address"
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              {startSearchResults.length > 0 && (
                <ul className="search-results mt-2">
                  {startSearchResults.map((result) => (
                    <li
                      key={result.id}
                      onClick={() => handleSelectResult(result, setStart, setStartAddress, setStartSearchResults)}
                      className="cursor-pointer hover:bg-gray-900 p-2"
                    >
                      {result.address.label}
                    </li>
                  ))}
                </ul>
              )}
              <p className="text-sm text-gray-600 mb-4">Enter the starting point of your journey. Example: "123 Main St, City"</p>
              
            </div>
            
            <div>
              <label className="block mb-2">
                End:
                <input
                  type="text"
                  value={endAddress}
                  onChange={(e) => handleAddressChange(e, setEndAddress, setEndSearchResults)}
                  placeholder="Enter end address"
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Enter the destination of your journey. Example: "456 Elm St, City"</p>
              {endSearchResults.length > 0 && (
                <ul className="search-results mt-2">
                  {endSearchResults.map((result) => (
                    <li
                      key={result.id}
                      onClick={() => handleSelectResult(result, setEnd, setEndAddress, setEndSearchResults)}
                      className="cursor-pointer hover:bg-gray-100 p-2"
                    >
                      {result.address.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            
            <div>
              <label className="block mb-2">
                Battery Capacity (kWh):
                <input
                  type="number"
                  value={batteryCapacity}
                  onChange={(e) => setBatteryCapacity(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Total capacity of your vehicle's battery. Example: 70 kWh</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Initial Charge (%):
                <input
                  type="number"
                  value={initialCharge}
                  onChange={(e) => setInitialCharge(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Current charge level of your battery. Example: 80%</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Minimum Charge at Arrival (%):
                <input
                  type="number"
                  value={minChargeAtArrival}
                  onChange={(e) => setMinChargeAtArrival(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Desired minimum charge level at destination. Example: 20%</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Charging Curve:
                <select 
                  value={chargingCurve} 
                  onChange={(e) => setChargingCurve(e.target.value)}
                  className="mt-1 w-full p-2 border rounded"
                >
                  <option value="linear">Linear</option>
                  <option value="fast">Fast</option>
                  <option value="slow">Slow</option>
                </select>
              </label>
              <p className="text-sm text-gray-600 mb-4">Charging speed profile of your vehicle. Example: Fast</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Connector Type:
                <select 
                  value={connectorType} 
                  onChange={(e) => setConnectorType(e.target.value)}
                  className="mt-1 w-full p-2 border rounded"
                >
                  <option value="">All</option>
                  <option value="iec62196Type1Combo">Combo 1 (IEC 62196)</option>
                  <option value="iec62196Type2Combo">Combo 2 (IEC 62196)</option>
                  <option value="CHADEMO">CHAdeMO</option>
                  <option value="NEMA_5_20">NEMA 5-20</option>
                </select>
              </label>
              <p className="text-sm text-gray-600 mb-4">Charging connector type of your vehicle. Example: Combo 2 (IEC 62196)</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Departure Time:
                <TimePicker
                  value={departureTime}
                  onChange={(value: Value) => setDepartureTime(value)}
                  disableClock={true}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Planned departure time. Example: 09:00 AM</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Max Charge (%):
                <input
                  type="number"
                  value={maxCharge}
                  onChange={(e) => setMaxCharge(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Maximum charge level for your battery. Example: 100%</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Max Charge After Charging Station (%):
                <input
                  type="number"
                  value={maxChargeAfterChargingStation}
                  onChange={(e) => setMaxChargeAfterChargingStation(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Maximum charge level after using a charging station. Example: 80%</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Min Charge At Charging Station (%):
                <input
                  type="number"
                  value={minChargeAtChargingStation}
                  onChange={(e) => setMinChargeAtChargingStation(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Minimum charge level when arriving at a charging station. Example: 10%</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Min Charge At Destination (%):
                <input
                  type="number"
                  value={minChargeAtDestination}
                  onChange={(e) => setMinChargeAtDestination(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Minimum desired charge level at the final destination. Example: 20%</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Charging Setup Duration (s):
                <input
                  type="number"
                  value={chargingSetupDuration}
                  onChange={(e) => setChargingSetupDuration(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Time needed to set up charging (in seconds). Example: 300s (5 minutes)</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Auxiliary Consumption (kWh):
                <input
                  type="number"
                  value={auxiliaryConsumption}
                  onChange={(e) => setAuxiliaryConsumption(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Energy consumed by auxiliary systems (e.g., AC). Example: 1.8 kWh</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Ascent (m):
                <input
                  type="number"
                  value={ascent}
                  onChange={(e) => setAscent(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Energy consumed per meter of ascent. Example: 9 Wh/m</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Descent (m):
                <input
                  type="number"
                  value={descent}
                  onChange={(e) => setDescent(Number(e.target.value))}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Energy recovered per meter of descent. Example: 4.3 Wh/m</p>
            </div>
            
            <div>
              <label className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={makeReachable}
                  onChange={(e) => setMakeReachable(e.target.checked)}
                  className="mr-2"
                />
                Make Reachable
              </label>
              <p className="text-sm text-gray-600 mb-4">Ensure the route is reachable with given parameters</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Free Flow Speed Table:
                <input
                  type="text"
                  value={freeFlowSpeedTable}
                  onChange={(e) => setFreeFlowSpeedTable(e.target.value)}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Consumption rates at different speeds. Format: "speed,consumption,..."</p>
            </div>
            
            <div>
              <label className="block mb-2">
                Traffic Speed Table:
                <input
                  type="text"
                  value={trafficSpeedTable}
                  onChange={(e) => setTrafficSpeedTable(e.target.value)}
                  className="mt-1 w-full p-2 border rounded"
                />
              </label>
              <p className="text-sm text-gray-600 mb-4">Consumption rates in traffic. Format: "speed,consumption,..."</p>
            </div>
            
            <button 
              onClick={handleRoute}
              className="w-full bg-cyber-blue text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
            >
              Get Route
            </button>
        </div>
    </div>
  );
};