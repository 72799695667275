import React, { useCallback, useState } from 'react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import './index.css';
import 'leaflet/dist/leaflet.css';
import './App.css';
import { ChargePoint } from './services/evChargePoints';
import { SectionWaypoint, RouteLayer } from './components/route-layer';
import { Sidebar } from './components/sidebar-component';
import { ChargePointsLayer } from './components/charge-points-layer';
import L from 'leaflet';

const MapBoundsHandler = ({ bounds }: { bounds: L.LatLngBoundsExpression | null }) => {
  const map = useMap();
  
  React.useEffect(() => {
    if (bounds) {
      map.flyToBounds(bounds);
    }
  }, [bounds, map]);

  return null;
};


const App: React.FC = () => {
  const [sections, setSections] = useState<SectionWaypoint[]>([]);
  const [mapBounds, setMapBounds] = useState<L.LatLngBoundsExpression | null>(null);

  const handleSetMapBounds = useCallback((bounds: L.LatLngBoundsExpression) => {
    setMapBounds(bounds);
  }, []);


  return (
    <div className="app">
      <div className={`map-overlay`}></div>
      <Sidebar 
        setSections={setSections}
        setMapBounds={handleSetMapBounds}
      />
      <div className="map">
        <MapContainer
          center={[-37.8136, 144.9631]}
          zoom={13}
          style={{ height: '100vh', width: '100%' }}
          preferCanvas={true}
        >
          <TileLayer
            url={`https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png?style=lite.night&ppi=400&size=512&apiKey=${process.env.REACT_APP_HERE_API_KEY}`}
            attribution='&copy; <a href="https://www.here.com/">HERE</a>'
          />
          <ChargePointsLayer />
          <RouteLayer sections={sections} />
          <MapBoundsHandler bounds={mapBounds} />
        </MapContainer>
      </div>
    </div>
  );
};

export default App;
