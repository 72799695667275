import axios from 'axios';

interface GeocodingParams {
  query: string;
}

export interface GeocodeItem {
    title: string;
    id: string;
    resultType: string;
    houseNumberType: string;
    address: {
        label: string;
        countryCode: string;
        countryName: string;
        stateCode: string;
        state: string;
        countyCode: string;
        county: string;
        city: string;
        district: string;
        street: string;
        postalCode: string;
        houseNumber: string;
    };
    position: {
        lat: number;
        lng: number;
    };
    access: {
        lat: number;
        lng: number;
    }[];
    mapView: {
        west: number;
        south: number;
        east: number;
        north: number;
    };
    scoring: {
        queryScore: number;
        fieldScore: {
            streets: number[];
            houseNumber: number;
        };
    };
}

export interface GeocodingResponse {
    items: GeocodeItem[];
}

export const searchLocation = async (params: GeocodingParams): Promise<GeocodingResponse> => {
  const response = await axios.get<GeocodingResponse>('https://geocode.search.hereapi.com/v1/geocode', {
    params: {
      q: params.query,
      apiKey: process.env.REACT_APP_HERE_API_KEY,
    },
  });
  return response.data;
};
